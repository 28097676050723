// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // TODO: Specify URI for your development environment
  appUrl: 'https://activity-feed-ui.dev.zonarsystems.net/',
  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'development',
  name: 'development',
  apiBase: 'https://api-dev.zonarsystems.net',
  reverseGeocoderApiBase: 'https://zonar-nonprod-dev.apigee.net',
  apiApigeePrefix: '/activity',
  devTools: true,
  zonarApps: {
    gtcxMapsBaseUrl: 'https://maps.dev.zonarsystems.net',
    alertManagementBaseUrl: 'https://alertmanagement.dev.zonarsystems.net'
  },
  eventSubscriptionApiBase: 'https://event-subscription-api.dev.zonarsystems.net/v1',
  alertManagementAdminConfigs: {
    roleId: 'dbe9ffe5-0dd0-49d6-a824-f789f23ade35',
    applicationId: 'dbe9ffe5-0dd0-49d6-a824-f789f23ade35'
  },
  auth: {
    clientID: 'FT0oJDvWOA4SCNfHpYT10yktiiUzQseA',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: 'd21d3cdb-0a15-479f-a6fb-640a21346b26',
    defaultZonarRole: '379f9627-b82c-4086-b3dc-0030b1e5ca0e',
    alertManagementAppId: 'dbe9ffe5-0dd0-49d6-a824-f789f23ade35',
    alertManagementRoleId: 'b8e73ba1-577f-4cc4-b29a-324c0d8ec9c9',
    useRefreshTokens: true
  },
  userPreferenceApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  },
  datadog: {
    applicationId: 'd9510c6e-b793-4156-abbd-c755919f1866',
    clientToken: 'pub7c67ecc6ecb6ec6a494008ed10faf740',
    siteUrl: 'us5.datadoghq.com'
  },
  devCycle: {
    clientId: 'dvc_client_e53775ea_d05c_484a_adc6_80e4d946ff92_be0127b'
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '4-KiGGwpt35kxwSPey_o-w',
    environment: 'env-3'
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  production: false,
  region: 'NA',
  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net/core/v1'
  },
  i18n: {
    supportedLanguages: ['en-US'],
    defaultLanguage: 'en-US'
  },
  featureFlags: {
    refreshActivityBtn: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
